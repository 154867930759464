import React, { useState } from "react"
import { Link } from "gatsby"
import HomeIcon from "@material-ui/icons/Home"
import PersonIcon from "@material-ui/icons/Person"
import DescriptionIcon from "@material-ui/icons/Description"
import { Mail } from "@material-ui/icons"
import MenuIcon from "@material-ui/icons/Menu"
import { css } from '@emotion/core';
const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
}

const MobileNavbar = ({ color }) => {
  const [openDrawer, setOpenDrawer] = useState(false)
  return (
    <nav
      className="header__mobile"
      initial="hidden"
      animate="visible"
      variants={variants}
    >
      <div
        className="header__barsContainer"
        onClick={() => setOpenDrawer(prev => !prev)}
      >
        <MenuIcon />
      </div>
      <ul className={`header__mobileMainContent ${openDrawer && "open"}`}>
        <li>
          <Link
            to="/"
            activeClassName="active"
            css={css`
              &.active > * {
                color: ${color};
              }
            `}
          >
            <HomeIcon />
            <span>Home</span>
          </Link>
        </li>
        <li>
          <Link
            to="/about"
            activeClassName="active"
            css={css`
              &.active > * {
                color: ${color};
              }
            `}
          >
            <PersonIcon />
            <span>About</span>
          </Link>
        </li>
        <li>
          <Link
            to="/portfolio"
            activeClassName="active"
            css={css`
              &.active > * {
                color: ${color};
              }
            `}
          >
            <DescriptionIcon />
            <span>Portfolio</span>
          </Link>
        </li>
        <li>
          <Link
            to="/contact"
            activeClassName="active"
            css={css`
              &.active > * {
                color: ${color};
              }
            `}
          >
            <Mail />
            <span>Contact</span>
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default MobileNavbar
