import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import {Global, css} from "@emotion/react"
const Layout = ({children}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <Global
        styles={css`
          @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

          *,
          *::before,
          *::after {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            font-family: "Poppins", sans-serif;
          }
          body {
            min-height: 100vh;
            background: #111;
            color: white;
          }
          #gatsby-focus-wrapper > main {
            width: 84vw;
            margin: auto;
            max-width: 1400px;
            display: flex;
            justify-content: center;
          }

          a {
            text-decoration: none;
          }

          ul {
            list-style: none;
          }

          .react-sweet-progress-circle-outer {
            position: relative;
          }
          .react-sweet-progress-symbol-absolute {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          @media (min-width: 450px) {
            .gatsby-image-wrapper {
              height: inherit;
            }
          }
        `}
      />
      <main>{children}</main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
