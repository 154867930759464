import React, { useState } from "react"
import green from "../images/light-green.png"
import orange from "../images/orange.png"
import purple from "../images/purple.png"
import red from "../images/red.png"
import skyblue from "../images/sky-blue.png"
import yellow from "../images/yellow.png"
import white from "../images/white.png"
import brown from "../images/brown.png"
import { css } from "@emotion/core"
import SettingsIcon from "@material-ui/icons/Settings"
import CancelIcon from "@material-ui/icons/Cancel"

const Colors = ({ dispatch }) => {
  const [clicked, setClicked] = useState(false)

  const handleColorChange = newColor => {
    dispatch({
      type: "UPDATE_COLOR",
      payload: {
        color: newColor,
      },
    })
  }
  return (
    <div className="colors" >
      {clicked ? (
        <div
          className="colors__container"
          css={css`
            position: relative;
            background: #222;
            color: #fff;
            border-radius: 10px;
            padding: 20px;
            & img {
              cursor: pointer;
            }
          `}
        >
          <div style={{ textAlign: "center", marginBottom: 10, marginTop: 10}}>
            Color Picker
          </div>
          <div>
            <CancelIcon
              css={css`
                position: absolute;
                top: 0;
                right: 0;
                cursor: pointer;
                color: #fff;
              `}
              onClick={() => setClicked(false)}
            />
            <img
              src={green}
              alt="green"
              width="30"
              onClick={() => handleColorChange("lightgreen")}
            />
            <img
              src={brown}
              alt="brown"
              width="30"
              onClick={() => handleColorChange("brown")}
            />
            <img
              src={orange}
              alt="orange"
              width="30"
              width="30"
              onClick={() => handleColorChange("#EFCC00")}
            />
            <img
              src={purple}
              alt="purple"
              width="30"
              width="30"
              onClick={() => handleColorChange("purple")}
            />
          </div>
          <div>
            <img
              src={red}
              alt="red"
              width="30"
              width="30"
              onClick={() => handleColorChange("red")}
            />
            <img
              src={skyblue}
              alt="skyblue"
              width="30"
              width="30"
              onClick={() => handleColorChange("skyblue")}
            />
            <img
              src={white}
              alt="white"
              width="30"
              width="30"
              onClick={() => handleColorChange("#B2BEB5")}
            />
            <img
              src={yellow}
              alt="yellow"
              width="30"
              width="30"
              onClick={() => handleColorChange("#ffb400")}
            />
          </div>
        </div>
      ) : (
        <div
          className="icon__container"
          css={css`
            & > svg {
              transition: 1.5s;
              animation-name: rotateGear;
              animation-duration: 2s;
              animation-iteration-count: infinite;
              animation-timing-function: linear;
              cursor: pointer;
            }
            @keyframes rotateGear {
              from {
                transform: rotate(0deg);
              }
              to {
                transform: rotate(360deg);
              }
            }
          `}
          onClick={() => setClicked(true)}
        >
          <SettingsIcon />
        </div>
      )}
    </div>
  )
}

export default Colors
