import React from "react"
import { Link } from "gatsby"
import HomeIcon from "@material-ui/icons/Home"
import PersonIcon from "@material-ui/icons/Person"
import DescriptionIcon from "@material-ui/icons/Description"
import { Mail } from "@material-ui/icons"
import { Avatar } from "@material-ui/core"
import { css } from '@emotion/core'


const PCNavbar = ({ color }) => {
  

  const LinkStyle = css`
    &:hover > div,
    &.active > div {
      background: ${color};
      color: white;
    }
`
  
  return (
    <nav className="header__pc">
      <ul className="header__PCMainContent">
        <li>
          <Link to="/" css={LinkStyle} activeClassName="active">
            <Avatar>
              <HomeIcon />
            </Avatar>
            <div>
              <span>HOME</span>
            </div>
          </Link>
        </li>
        <li>
          <Link to="/about" css={LinkStyle} activeClassName="active">
            <Avatar>
              <PersonIcon />
            </Avatar>
            <div>
              <span>ABOUT</span>
            </div>
          </Link>
        </li>
        <li>
          <Link to="/portfolio" css={LinkStyle} activeClassName="active">
            <Avatar>
              <DescriptionIcon />
            </Avatar>
            <div>
              <span>PORTFOLIO</span>
            </div>
          </Link>
        </li>
        <li>
          <Link to="/contact" css={LinkStyle} activeClassName="active">
            <Avatar>
              <Mail />
            </Avatar>
            <div>
              <span>CONTACT</span>
            </div>
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default PCNavbar
