import PropTypes from "prop-types"
import React from "react"
import "../css/Header.css"
import MobileNavbar from './MobileNavbar'
import PCNavbar from './PCNavbar'
import {useStateValue} from '../contextProvider/StateProvider'
import Colors from "./colors"
const Header = ({siteTitle}) => {
  
  const [{color}, dispatch] = useStateValue();
  return (
    <div className="header">
      {/* Navbar Mobile */}
      <MobileNavbar color={color} />
      {/* PC */}
      <PCNavbar color={color} />
      <Colors dispatch={dispatch}/>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
